const LocationPin = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99994 9.99989C8.65677 9.99989 9.99989 8.65677 9.99989 6.99994C9.99989 5.34312 8.65677 4 6.99994 4C5.34312 4 4 5.34312 4 6.99994C4 8.65677 5.34312 9.99989 6.99994 9.99989Z"
      stroke="#111111"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99989 1C8.59116 1 10.1173 1.63213 11.2424 2.75733C12.3676 3.88252 12.9998 5.40862 12.9998 6.99989C12.9998 9.72544 8.94065 16.5597 7.49988 18.7317C7.44515 18.8142 7.37085 18.8819 7.28361 18.9287C7.19637 18.9755 7.0989 19 6.99989 19C6.90088 19 6.80341 18.9755 6.71617 18.9287C6.62892 18.8819 6.55463 18.8142 6.4999 18.7317C5.05912 16.5597 1 9.72544 1 6.99989C1 5.40862 1.63213 3.88252 2.75733 2.75733C3.88252 1.63213 5.40862 1 6.99989 1V1Z"
      stroke="#111111"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LocationPin;
