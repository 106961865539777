import clsx from 'clsx';

const ChevronBackButton = (props: React.ComponentProps<'button'>) => (
  <button
    type="button"
    {...props}
    className={clsx(
      'p-3 -mx-3 pointer-events-auto outline-none focus:outline-none',
      props.className
    )}
  >
    <svg width="15" height="24" viewBox="0 0 15 24" fill="none">
      <path d="M14 1L2 12L14 23" stroke="black" strokeWidth="2" />
    </svg>
  </button>
);

export default ChevronBackButton;
