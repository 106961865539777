import moment, { Moment } from 'moment';
import Input from './Input';

const defaultLocale = 'fr-FR';

interface Props {
  numberOfMonths?: number;
  className?: string;
  inputRef?: any;
  error?: boolean | string;
  placeholder?: string;
  name: string;
  label?: string;
  date?: string;
  startDate?: string;
  endDate?: string;
  onDatesChange: ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  onFocusChange: () => void;
  focusedInput: 'startDate' | 'endDate' | null;
  unavailableDates: string[];
  fetchUnavailableDates?: ({ from, to }: { from: string; to: string }) => void;
  onFocus?: () => void;
}

const DateRangePicker = ({
  numberOfMonths,
  className,
  name,
  error,
  label,
  date,
  placeholder,
  startDate,
  endDate,
  onDatesChange,
  onFocusChange,
  focusedInput,
  fetchUnavailableDates,
  unavailableDates = [],
  onFocus,
  inputRef,
}: Props) => {
  moment.locale(typeof navigator !== 'undefined' ? navigator.language : defaultLocale);

  const onNextMonthClick = (currentDate: Moment) => {
    if (fetchUnavailableDates) {
      const from = currentDate.subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const to = currentDate.add(3, 'months').endOf('month').format('YYYY-MM-DD');
      fetchUnavailableDates({ from, to });
    }
  };

  const onPrevMonthClick = (currentDate: Moment) => {
    if (fetchUnavailableDates) {
      const from = currentDate.subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
      const to = currentDate.add(3, 'months').endOf('month').format('YYYY-MM-DD');
      fetchUnavailableDates({ from, to });
    }
  };

  return (
    <Input
      className={className}
      inputRef={inputRef}
      error={error}
      startDate={startDate ? moment(startDate) : null}
      endDate={endDate ? moment(endDate) : null}
      placeholder={placeholder}
      name={name}
      date={date ? moment(date) : null}
      label={label}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      focusedInput={focusedInput}
      unavailableDates={unavailableDates}
      onNextMonthClick={onNextMonthClick}
      onPrevMonthClick={onPrevMonthClick}
      onFocus={onFocus}
      numberOfMonths={numberOfMonths}
    />
  );
};

export default DateRangePicker;
