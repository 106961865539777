import { useState } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import isInteger from 'lodash/isInteger';
import Button from 'components/Button';
import { useGooglePlaces } from 'components/use-google-places';
import { PrivateRoomType } from 'generated/graphql';
import MobileSearchFullscreenManager, { FocusableInputList } from './MobileSearchFullscreenManager';
import { computeLocation } from './Search/utils';
import { MobileSearch, SearchBarData } from './types';

interface Props {
  className?: string;
  data: Partial<SearchBarData>;
  onSubmit: (data: SearchBarData) => void;
}

const MobileSearchBar = ({ className, data, onSubmit }: Props) => {
  const intl = useIntl();
  const { fetchPlaceDetails } = useGooglePlaces();

  const mapAreaText = intl.formatMessage({
    id: 'search_bar.location.map_area',
    defaultMessage: 'Map area',
  });
  const location = data.location?.query?.split('|');
  const initialLocationValue = data.boundingBox ? mapAreaText : location?.[0] || '';

  const [search, setSearch] = useState<MobileSearch>({
    address: location?.[0],
    placeId: location?.[1],
    startDate: data.startDate,
    endDate: data.endDate,
    minBudget: data.minBudget,
    maxBudget: data.maxBudget,
    rooms: {
      [PrivateRoomType.Balcony]: data.privateRooms?.BALCONY || false,
      [PrivateRoomType.Bathroom]: data.privateRooms?.BATHROOM || false,
      [PrivateRoomType.Kitchen]: data.privateRooms?.KITCHEN || false,
      [PrivateRoomType.Toilet]: data.privateRooms?.TOILET || false,
    },
  });

  const [focusedInput, setFocusedInput] = useState<FocusableInputList>(null);
  const [showInputsManager, setShowInputsManager] = useState(false);

  const handleSubmit = async (e?: React.BaseSyntheticEvent): Promise<void> => {
    if (e?.preventDefault) {
      e.preventDefault();
      e.persist();
    }

    if (!search.placeId || !search.address) {
      setFocusedInput('location');
      setShowInputsManager(true);
      return;
    }

    if (!search.startDate) {
      setFocusedInput('startDate');
      setShowInputsManager(true);
      return;
    }

    if (!search.endDate) {
      setFocusedInput('endDate');
      setShowInputsManager(true);
      return;
    }

    if (!isInteger(search.maxBudget)) {
      setFocusedInput('budget');
      setShowInputsManager(true);
      return;
    }

    const location = await computeLocation(search.placeId, search.address, fetchPlaceDetails);

    onSubmit({
      location,
      startDate: search.startDate,
      endDate: search.endDate,
      maxBudget: search.maxBudget,
      minBudget: search.minBudget,
      privateRooms: search.rooms,
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div
        className={clsx(
          'flex flex-wrap bg-white shadow-md divide-y divide-gray-300 lg:divide-y-0 lg:divide-x',
          className
        )}
      >
        <button
          className="py-5 md:py-6 w-full text-left outline-none focus:outline-none"
          onClick={() => {
            setFocusedInput('location');
            setShowInputsManager(true);
          }}
          type="button"
        >
          <div className="text-left inline-flex flex-col items-start justify-center flex-grow group px-4 md:px-6 w-full truncate">
            <div className="text-sm font-light">
              {intl.formatMessage({
                id: 'search_bar.location.label',
                defaultMessage: 'Location',
              })}
            </div>
            {initialLocationValue ? (
              <div className="w-full text-sm md:text-lg font-medium text-black truncate overflow-ellipsis">
                {initialLocationValue}
              </div>
            ) : (
              <div className="w-full text-sm md:text-lg font-light text-gray-600">
                {intl.formatMessage({
                  id: 'search_bar.location.placeholder',
                  defaultMessage: 'Enter an address',
                })}
              </div>
            )}
          </div>
        </button>

        <div className="flex md:flex-row w-full sm:w-full md:w-full lg:w-6/12 xl:w-6/12 divide-x divide-gray-300">
          <button
            className="py-5 md:py-6 w-full text-left outline-none focus:outline-none"
            onClick={() => {
              setFocusedInput('startDate');
              setShowInputsManager(true);
            }}
            type="button"
          >
            <div className="text-left inline-flex flex-col items-start justify-center flex-grow group px-4 md:px-6 w-full">
              <div className="text-sm font-light truncate">
                {intl.formatMessage({
                  id: 'search_bar.checkin.label',
                  defaultMessage: 'Checkin',
                })}
              </div>
              {search.startDate ? (
                <div className="w-full text-sm md:text-lg font-medium text-black">
                  {intl.formatDate(search.startDate)}
                </div>
              ) : (
                <div className="w-full text-sm md:text-lg font-light text-gray-600">
                  {intl.formatMessage({
                    id: 'search_bar.checkin.no_content',
                    defaultMessage: 'Add a date',
                  })}
                </div>
              )}
            </div>
          </button>
          <button
            className="py-5 md:py-6 w-full text-left outline-none focus:outline-none"
            onClick={() => {
              setFocusedInput('endDate');
              setShowInputsManager(true);
            }}
            type="button"
          >
            <div className="text-left inline-flex flex-col items-start justify-center flex-grow group px-4 md:px-6 w-full">
              <div className="text-sm font-light truncate">
                {intl.formatMessage({
                  id: 'search_bar.checkout.label',
                  defaultMessage: 'Checkout',
                })}
              </div>
              {search.endDate ? (
                <div className="w-full text-sm md:text-lg font-medium text-black">
                  {intl.formatDate(search.endDate)}
                </div>
              ) : (
                <div className="w-full text-sm md:text-lg font-light text-gray-600">
                  {intl.formatMessage({
                    id: 'search_bar.checkout.no_content',
                    defaultMessage: 'Add a date',
                  })}
                </div>
              )}
            </div>
          </button>
        </div>

        <button
          className="py-5 md:py-6 w-full text-left outline-none focus:outline-none"
          onClick={() => {
            setFocusedInput('budget');
            setShowInputsManager(true);
          }}
          type="button"
        >
          <div className="text-left inline-flex flex-col items-start justify-center flex-grow group px-4 md:px-6 w-full">
            <div className="text-sm font-light truncate">
              {intl.formatMessage({
                id: 'search_accommodations.filters.budget.title',
                defaultMessage: 'Budget',
              })}
            </div>
            {Number.isInteger(search.maxBudget) ? (
              <div className="w-full text-sm md:text-lg font-medium text-black">
                {`${search.minBudget || 0} - ${search.maxBudget}`}
              </div>
            ) : (
              <div className="w-full text-sm md:text-lg font-light text-gray-600">
                {intl.formatMessage({
                  id: 'search_bar.budget.placeholder',
                  defaultMessage: 'Enter your budget',
                })}
              </div>
            )}
          </div>
        </button>
      </div>
      <div className="py-2.5 md:py-4 w-full">
        <Button className="w-full font-medium h-16 text-black flex items-center justify-center bg-yellow-500 rounded-2xl outline-none focus:outline-none hover:bg-yellow-500">
          {intl.formatMessage({
            id: 'search_bar.mobile.button.search',
            defaultMessage: 'Search',
          })}
        </Button>
      </div>

      <MobileSearchFullscreenManager
        data={data}
        focusedInput={focusedInput}
        onHide={() => {
          setFocusedInput(null);
          setShowInputsManager(false);
        }}
        onSubmit={handleSubmit}
        visible={showInputsManager}
        setSearch={setSearch}
        search={search}
      />
    </form>
  );
};

export default MobileSearchBar;
