import { IntlShape } from 'react-intl';
import { BORDEAUX_COORDINATES, LYON_COORDINATES, PARIS_COORDINATES } from 'lib/constants';
import { Location } from '../types';

export const getDefaultLocations = (intl: IntlShape) => [
  {
    id: 'paris',
    place_id: 'colette-paris',
    description: intl.formatMessage({
      id: 'search_bar.location.default_paris',
      defaultMessage: 'See rooms in Paris',
    }),
  },
  {
    id: 'lyon',
    place_id: 'colette-lyon',
    description: intl.formatMessage({
      id: 'search_bar.location.default_lyon',
      defaultMessage: 'See rooms in Lyon',
    }),
  },
  {
    id: 'bordeaux',
    place_id: 'colette-bordeaux',
    description: intl.formatMessage({
      id: 'search_bar.location.default_bordeaux',
      defaultMessage: 'See rooms in Bordeaux',
    }),
  },
];

export const computeLocation = async (
  placeId: string,
  address: string,
  fetchPlaceDetails: (placeId?: string) => Promise<google.maps.GeocoderResult | undefined>
) => {
  const location: Location = { lat: undefined, lng: undefined };
  if (placeId.startsWith('colette-')) {
    if (placeId === 'colette-paris') {
      location.lat = PARIS_COORDINATES[0];
      location.lng = PARIS_COORDINATES[1];
      location.query = address + '|colette-paris';
    } else if (placeId === 'colette-lyon') {
      location.lat = LYON_COORDINATES[0];
      location.lng = LYON_COORDINATES[1];
      location.query = address + '|colette-lyon';
    } else {
      location.lat = BORDEAUX_COORDINATES[0];
      location.lng = BORDEAUX_COORDINATES[1];
      location.query = address + '|colette-bordeaux';
    }
  } else {
    const place = await fetchPlaceDetails(placeId);
    if (place) {
      location.lat = place.geometry.location.lat();
      location.lng = place.geometry.location.lng();
      location.query = address + '|' + placeId;
    }
  }

  return location;
};
