import { useEffect, useState, useRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { useClickAway, useDebounce } from 'react-use';
import clsx from 'clsx';
import { useGooglePlaces } from 'components/use-google-places';
import { Input } from './Input';
import { getDefaultLocations } from './utils';

interface Selection {
  placeId?: string;
  address?: string;
}

interface Props {
  onSelect: (select: Selection) => void;
  selected: Selection;
  value?: string;
  className?: string;
}

export const SearchAutocomplete = forwardRef<HTMLInputElement, Props>(
  ({ onSelect, selected, value, className }, ref) => {
    const intl = useIntl();
    const containerRef = useRef(null);
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(selected.address || '');
    const { fetchPredictions } = useGooglePlaces();

    useDebounce(async () => setPredictions(await fetchPredictions(inputValue)), 500, [inputValue]);

    useClickAway(containerRef, () => setOpen(false));

    useEffect(() => {
      if (value) setInputValue(value);
    }, [value]);

    return (
      <div className={clsx(className, 'relative')} ref={containerRef}>
        <Input
          value={inputValue}
          onClick={() => setOpen(true)}
          onChange={(e) => setInputValue(e.target.value)}
          className="appearance-none w-full h-auto text-black placeholder-gray-600 bg-white border-white outline-none text-lg font-medium overflow-ellipsis focus:border-white"
          placeholder={intl.formatMessage({
            id: 'search_bar.location.placeholder',
          })}
          label={intl.formatMessage({
            id: 'search_bar.location.label',
            defaultMessage: 'Location',
          })}
          ref={ref}
        />

        {open ? (
          <ul
            className={clsx(
              'bg-white py-5 shadow-lg absolute top-24 rounded-2xl text-black origin-bottom list-none left-0 z-50 right-0 w-full min-w-min'
            )}
          >
            {(predictions.length ? predictions : getDefaultLocations(intl)).map((prediction) => (
              <li
                key={prediction.place_id}
                onClick={() => {
                  onSelect({ placeId: prediction.place_id, address: prediction.description });
                  setInputValue(prediction.description);
                  setOpen(false);
                }}
                className={clsx(
                  'px-5 font-sans text-base text-black hover:bg-gray-200 py-4 cursor-pointer whitespace-nowrap',
                  {
                    '!font-medium': selected.placeId === prediction.place_id,
                  }
                )}
              >
                {prediction.description}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
);
