import { forwardRef } from 'react';
import clsx from 'clsx';

interface Props extends React.ComponentPropsWithRef<'button'> {
  label: React.ReactNode;
  noValueContent?: React.ReactNode;
}

const DateButton = forwardRef<HTMLButtonElement, Props>(
  ({ label, noValueContent, children, ...props }, ref) => (
    <button {...props} type="button" className={clsx(props.className, 'text-left')} ref={ref}>
      <span className="text-sm font-light truncate block">{label}</span>
      <span className="text-sm font-light md:text-lg md:font-medium truncate block">
        {children || noValueContent}
      </span>
    </button>
  )
);

export default DateButton;
