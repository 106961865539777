import { useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import { FormattedMessage } from 'react-intl';
import { find } from 'lodash';
import moment, { Moment } from 'moment';

const minimumNights = 30;

export interface Props {
  numberOfMonths?: number;
  startDate: Moment | null;
  endDate: Moment | null;
  onDatesChange: ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  onFocusChange?: (focus: 'startDate' | 'endDate' | null) => void;
  focusedInput: 'startDate' | 'endDate' | null;
  unavailableDates?: string[] | null;
  onNextMonthClick?: (date: Moment) => void;
  onPrevMonthClick?: (date: Moment) => void;
}

const Picker = ({
  numberOfMonths,
  startDate,
  endDate,
  onDatesChange,
  onFocusChange,
  focusedInput,
  unavailableDates = [],
  onNextMonthClick,
  onPrevMonthClick,
}: Props) => {
  const isDayBlocked = (m: Moment) =>
    m.isBefore(moment()) || !!find(unavailableDates, (date) => m.format('YYYY-MM-DD') === date);

  const [selectingDates, setSelectingDates] = useState<boolean>(!!startDate && !endDate);

  const innerOnDatesChange = ({
    startDate: start,
    endDate: end,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setSelectingDates(!end);
    onDatesChange({ startDate: start, endDate: end });
  };

  return (
    <DayPickerRangeController
      startDate={startDate}
      endDate={endDate}
      minDate={moment()}
      minimumNights={minimumNights}
      numberOfMonths={numberOfMonths || 1}
      noBorder
      initialVisibleMonth={() => startDate || moment()}
      onDatesChange={innerOnDatesChange}
      onFocusChange={(f) => {
        if (onFocusChange) onFocusChange(f);
      }}
      focusedInput={focusedInput}
      isDayBlocked={isDayBlocked}
      renderDayContents={(m: Moment) => (isDayBlocked(m) ? <s>{m.format('D')}</s> : m.format('D'))}
      onNextMonthClick={onNextMonthClick}
      onPrevMonthClick={onPrevMonthClick}
      hideKeyboardShortcutsPanel
      renderCalendarInfo={() =>
        selectingDates ? (
          <div className="pb-2 px-4 font-sans font-normal text-sm text-black">
            <FormattedMessage
              id="date_range_picker.helper_text.minimum_nights"
              defaultMessage="Select a minimum of {count} nights"
              values={{ count: minimumNights }}
            />
          </div>
        ) : null
      }
    />
  );
};

export default Picker;
