import { forwardRef } from 'react';
import clsx from 'clsx';

interface Props extends React.ComponentPropsWithRef<'input'> {
  label: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, Props>(({ label, ...props }, ref) => {
  return (
    <div
      className={clsx(
        props.className,
        'flex flex-col items-start justify-center flex-grow text-left cursor-pointer w-full'
      )}
    >
      <label className="text-sm font-light truncate">{label}</label>

      <input
        {...props}
        ref={ref}
        className="appearance-none w-full h-auto text-black placeholder-gray-600 bg-white border-white outline-none text-lg font-medium overflow-ellipsis focus:border-white"
      />
    </div>
  );
});
