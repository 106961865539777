import { Menu, Transition } from '@headlessui/react';
import { Moment } from 'moment';
import TextField from 'components/TextField';
import Picker from './Picker';

interface Props {
  numberOfMonths?: number;
  inputRef: any;
  error?: boolean | string;
  placeholder?: string;
  name: string;
  date: Moment | null;
  label?: string;
  startDate: Moment | null;
  endDate: Moment | null;
  onDatesChange: ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  onFocusChange: () => void;
  focusedInput: 'startDate' | 'endDate' | null;
  unavailableDates: string[];
  onNextMonthClick: (date: Moment) => void;
  onPrevMonthClick: (date: Moment) => void;
  onFocus?: () => void;
  className?: string;
}

type DateTextFieldProps = {
  inputRef: any;
  error?: boolean | string;
  placeholder?: string;
  name: string;
  value?: string;
  label?: string;
  onFocus?: () => void;
  required?: boolean;
  className?: string;
};

const DateTextField = ({
  onFocus,
  inputRef,
  error,
  placeholder,
  name,
  value,
  label,
  required,
  className,
}: DateTextFieldProps) => (
  <TextField
    className={className}
    onFocus={onFocus}
    placeholder={placeholder}
    name={name}
    value={value || ''}
    label={label}
    required={required}
    ref={inputRef}
    // eslint-disable-next-line
    // @ts-expect-error
    error={error}
    readOnly
  />
);

const Input = ({
  numberOfMonths,
  className,
  inputRef,
  error,
  placeholder,
  name,
  date,
  label,
  startDate,
  endDate,
  onDatesChange,
  onFocusChange,
  focusedInput,
  unavailableDates,
  onPrevMonthClick,
  onNextMonthClick,
  onFocus,
}: Props) => (
  <div>
    <div className="md:hidden">
      <DateTextField
        className={className}
        onFocus={onFocus}
        placeholder={placeholder}
        name={name}
        value={date?.format('YYYY-MM-DD')}
        label={label}
        required
        inputRef={inputRef}
        error={error}
      />
    </div>
    <div className="relative hidden w-full md:inline-block">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="w-full outline-none focus:outline-none">
              <DateTextField
                placeholder={placeholder}
                name={name}
                value={date?.format('YYYY-MM-DD')}
                label={label}
                required
                inputRef={inputRef}
                error={error}
                className={className}
              />
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 z-20 p-2 mt-2 origin-top bg-white shadow-lg outline-none cursor-auto xl:right-auto xl:left-0 rounded-2xl ring-1 ring-black ring-opacity-5"
              >
                <Menu.Item>
                  {() => (
                    <Picker
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={onDatesChange}
                      onFocusChange={onFocusChange}
                      focusedInput={focusedInput}
                      unavailableDates={unavailableDates}
                      onPrevMonthClick={onPrevMonthClick}
                      onNextMonthClick={onNextMonthClick}
                      numberOfMonths={numberOfMonths}
                    />
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  </div>
);

export default Input;
