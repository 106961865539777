import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useDebounce } from 'react-use';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import isInteger from 'lodash/isInteger';
import moment from 'moment';
import ChevronBackButton from 'components/ChevronBackButton';
import { Picker } from 'components/DateRangePicker';
import LocationPinIcon from 'components/LocationPinIcon';
import MultiRangeSlider from 'components/MultiRangeSlider';
import RequestsNavigationTab from 'components/RequestsNavigationTab';
import { useGooglePlaces } from 'components/use-google-places';
import { MobileSearch, SearchBarData } from './types';

const defaultLocale = 'fr-FR';
moment.locale(typeof navigator !== 'undefined' ? navigator.language : defaultLocale);

export type FocusableInputList = 'location' | 'startDate' | 'endDate' | 'budget' | null;

interface Props {
  data: Partial<SearchBarData>;
  focusedInput: FocusableInputList;
  onHide: () => void;
  onSubmit: () => void;
  visible: boolean;
  search: MobileSearch;
  setSearch: Dispatch<SetStateAction<MobileSearch>>;
}

const MobileSearchFullscreen = ({
  focusedInput,
  onHide,
  onSubmit,
  visible,
  search,
  setSearch,
}: Props) => {
  const intl = useIntl();
  const { fetchPredictions } = useGooglePlaces();
  const locationInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const steps = useMemo(() => ['location', 'startDate', 'endDate', 'budget'], []);

  const [currentDisplayedInput, setCurrentDisplayedInput] =
    useState<FocusableInputList>(focusedInput);
  const [allFilled, setAllFilled] = useState<boolean>(false);

  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  useDebounce(async () => setPredictions(await fetchPredictions(search.address)), 500, [
    search.address,
  ]);

  useEffect(() => setCurrentDisplayedInput(focusedInput), [focusedInput]);

  useEffect(() => {
    if (visible) {
      setAllFilled(
        !!search.address && !!search.startDate && !!search.endDate && isInteger(search.maxBudget)
      );
    }
  }, [search, visible]);

  useEffect(() => {
    if (currentDisplayedInput === 'location' && locationInputRef.current) {
      locationInputRef.current?.focus();
    }
  }, [currentDisplayedInput]);

  const goToNextStep = (next?: number) => {
    const currentIndex = steps.indexOf(currentDisplayedInput as string);
    let nextIndex = currentIndex + 1 > steps.length ? 0 : currentIndex + 1;
    if (typeof next === 'number') {
      nextIndex = next + 1 > steps.length ? 0 : next + 1;
    }

    if (!allFilled) {
      switch (nextIndex) {
        case 0:
          if (!search.address) {
            setCurrentDisplayedInput('location');
            return;
          }
          break;
        case 1:
          if (!search.startDate) {
            setCurrentDisplayedInput('startDate');
            return;
          }
          break;
        case 2:
          if (!search.endDate) {
            setCurrentDisplayedInput('endDate');
            return;
          }
          break;
        case 3:
          if (!isInteger(search.maxBudget)) {
            setCurrentDisplayedInput('budget');
            return;
          }
          break;
        default:
          break;
      }
      goToNextStep(nextIndex);
    }
  };

  const displayHeader = () => {
    switch (currentDisplayedInput) {
      case 'location':
        return (
          <div className=" text-left inline-flex flex-col items-start justify-center flex-grow group px-4 md:px-6 w-full text-lg font-medium truncate">
            <input
              className="w-full text-lg font-medium font-sans text-black placeholder-gray-600 outline-none overflow-ellipsis"
              id="location"
              name="location"
              type="text"
              placeholder={intl.formatMessage({
                id: 'search_bar.mobile.location.input.label',
                defaultMessage: 'Enter an address',
              })}
              value={search.address}
              onChange={(e) => setSearch((s) => ({ ...s, address: e.target.value }))}
              ref={locationInputRef}
              autoFocus
            />
          </div>
        );
      case 'startDate':
      case 'endDate':
        return (
          <h2 className="content-header text-center w-full">
            <FormattedMessage
              id="search_accommodations.filters.dates.title"
              defaultMessage="Dates"
            />
          </h2>
        );
      case 'budget':
        return (
          <h2 className="content-header text-center w-full">
            <FormattedMessage
              id="search_accommodations.filters.budget.title"
              defaultMessage="Budget"
            />
          </h2>
        );
      default:
        return null;
    }
  };

  const displayContent = () => {
    switch (currentDisplayedInput) {
      case 'location':
        return (
          <div className="border-t border-gray-300">
            <ul className="pl-6">
              {predictions.map((prediction) => (
                <li
                  onClick={() =>
                    setSearch((s) => ({
                      ...s,
                      address: prediction.description,
                      placeId: prediction.place_id,
                    }))
                  }
                  key={prediction.place_id}
                  className={clsx(
                    'h-16 text-lg text-black flex items-center px-4 md:px-6 cursor-pointer bg-white hover:bg-gray-200 border-b border-gray-300',
                    search.placeId === prediction.place_id ? 'font-medium' : 'font-normal'
                  )}
                  role="presentation"
                >
                  <LocationPinIcon className="mr-3" />
                  {prediction.description}
                </li>
              ))}
            </ul>
          </div>
        );

      case 'startDate':
      case 'endDate':
        return (
          <>
            <div className="flex space-x-1 px-8 my-6">
              <RequestsNavigationTab
                active={currentDisplayedInput === 'startDate'}
                className="outline-none focus:outline-none"
                onClick={() => {
                  setCurrentDisplayedInput('startDate');
                }}
              >
                <FormattedMessage
                  id="accommodation_sidebar.start_date.label"
                  defaultMessage="Start date"
                />
              </RequestsNavigationTab>

              <RequestsNavigationTab
                active={currentDisplayedInput === 'endDate'}
                className="outline-none focus:outline-none"
                onClick={() => {
                  setCurrentDisplayedInput('endDate');
                }}
              >
                <FormattedMessage
                  id="accommodation_sidebar.end_date.label"
                  defaultMessage="End date"
                />
              </RequestsNavigationTab>
            </div>

            <Picker
              startDate={search.startDate ? moment(search.startDate) : null}
              endDate={search.endDate ? moment(search.endDate) : null}
              focusedInput={currentDisplayedInput}
              onDatesChange={(dates) => {
                setSearch((s) => ({
                  ...s,
                  startDate: dates.startDate?.format('YYYY-MM-DD'),
                  endDate: dates.endDate?.format('YYYY-MM-DD'),
                }));

                if (currentDisplayedInput === 'startDate') {
                  setCurrentDisplayedInput('endDate');
                }
                if (currentDisplayedInput === 'endDate' && !dates.startDate) {
                  setCurrentDisplayedInput('startDate');
                }
                if (currentDisplayedInput === 'endDate' && !dates.startDate && !dates.endDate) {
                  goToNextStep();
                }
              }}
            />
          </>
        );

      case 'budget':
        return (
          <div className="px-8 pt-8 w-full text-left text-black text-lg">
            <p className="font-medium">
              <FormattedMessage
                id="search_bar.mobile.filters.budget.label"
                defaultMessage="Accommodation price"
              />
            </p>
            <p className="font-normal mb-5">
              <FormattedNumber
                value={search.minBudget || 0}
                style="currency"
                currency="eur"
                maximumFractionDigits={0}
                minimumFractionDigits={0}
              />
              <span> - </span>
              <FormattedNumber
                value={search.maxBudget || 0}
                style="currency"
                currency="eur"
                maximumFractionDigits={0}
                minimumFractionDigits={0}
              />
            </p>
            <MultiRangeSlider
              min={0}
              max={1000}
              defaultValues={{ minBudget: search.minBudget, maxBudget: search.maxBudget }}
              onChange={({ min, max }: { min: number; max: number }) =>
                setSearch((s) => ({ ...s, maxBudget: max, minBudget: min }))
              }
              step={50}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Transition
      show={visible}
      as={Fragment}
      enter="transform transition ease-in-out duration-200"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transform transition ease-in-out duration-200"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
    >
      <Dialog as={Fragment} static open={visible} onClose={onHide}>
        <div className="h-full overflow-y-auto bg-white fixed inset-0 overflow-hidden">
          <div className="h-full pb-6 flex flex-col justify-between">
            <div className="flex items-center h-20 content-wrapper">
              <ChevronBackButton onClick={onHide} />
              {displayHeader()}
            </div>
            <div className="flex flex-col flex-grow">{displayContent()}</div>
            <div className="px-6">
              <button
                type="button"
                className="w-full btn mt-4 bg-yellow-500 hover:bg-yellow-500 text-black outline-none focus:outline-none"
                onClick={() => {
                  if (allFilled) {
                    onSubmit();
                    onHide();
                  } else goToNextStep();
                }}
              >
                {allFilled ? (
                  <FormattedMessage id="search_bar.mobile.button.search" defaultMessage="Search" />
                ) : (
                  <FormattedMessage
                    id="fullscreen_date_range_picker.button.validate"
                    defaultMessage="Validate"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MobileSearchFullscreen;
