/* eslint-disable import/prefer-default-export */
// [lat, lng]
export const PARIS_COORDINATES = [48.864716, 2.349014];
export const LYON_COORDINATES = [45.7579454, 4.8001109];
export const BORDEAUX_COORDINATES = [44.8637124, -0.6210312];
export const COLETTE_CHECKLIST_URL =
  'https://s3.eu-west-1.amazonaws.com/assets.colette.club/static/La+checklist+de+Colette+pour+un+e%CC%81change+efficace_FR.pdf';
export const FAQ_LINK = 'https://help.colette.club/fr/';
export const CGU_HOST =
  'https://coletteclub.notion.site/CGU-h-tes-3e148b6699e6464c87f63bb3abcebef0';
export const CGU_GUEST =
  'https://coletteclub.notion.site/CGU-co-habitants-aa799d07bd7b4cc6ac94dfff167611aa';
export const CHARTER_URL =
  'https://coletteclub.notion.site/Ce-que-nous-attendons-de-nos-membres-La-Charte-Colette-7de2443fe3db4045b50fc998d6356da3';
export const HOUSING_ASSISTANCE_CERTIFICATE_URL =
  'https://s3.eu-west-1.amazonaws.com/assets.colette.club/static/Housing+Assistance+Certificate.pdf';
