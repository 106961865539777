import { useEffect, useState } from 'react';
import { Loader, LoaderStatus } from '@googlemaps/js-api-loader';

export const useGooglePlaces = () => {
  const [googleMapsLoader, setGMapsLoader] = useState<Loader>();

  useEffect(() => {
    if (googleMapsLoader == null) {
      const loader = new Loader({
        apiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
        version: 'weekly',
        libraries: ['places'],
        region: 'FR',
      });

      loader.loadCallback(() => setGMapsLoader(loader));

      loader.load();
    }

    return () => {
      if (googleMapsLoader != null) googleMapsLoader.deleteScript();
    };
  }, [googleMapsLoader]);

  const fetchPredictions = async (input?: string) => {
    if (googleMapsLoader?.status !== LoaderStatus.SUCCESS || !('google' in window) || !input) {
      return [];
    }

    const response = await new google.maps.places.AutocompleteService().getPlacePredictions({
      componentRestrictions: { country: 'fr' },
      types: ['geocode'],
      input,
    });

    return response.predictions;
  };

  const fetchPlaceDetails = async (placeId?: string) => {
    if (googleMapsLoader?.status !== LoaderStatus.SUCCESS || !('google' in window) || !placeId)
      return undefined;

    const response = await new google.maps.Geocoder().geocode({ placeId });

    return response.results?.[0];
  };

  return { loader: googleMapsLoader, fetchPredictions, fetchPlaceDetails };
};

export const getValueFromAddressComponent = (
  addressComponent: google.maps.GeocoderAddressComponent[],
  type: string
) => {
  return addressComponent.find((c) => c.types.includes(type))?.short_name;
};
