import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClickAway } from 'react-use';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment';
import DateButton from 'components/DateButton';
import { Picker } from 'components/DateRangePicker';
import { useGooglePlaces } from 'components/use-google-places';
import { BORDEAUX_COORDINATES, LYON_COORDINATES, PARIS_COORDINATES } from 'lib/constants';
import { SearchAutocomplete } from './Search/Autocomplete';
import { Input } from './Search/Input';
import SearchButton from './SearchButton';
import { Location, SearchBarData } from './types';

interface Props {
  data: Partial<SearchBarData>;
  onSubmit: (data: SearchBarData) => void;
  className?: string;
}

const SearchBar = ({ onSubmit, data, className }: Props) => {
  const intl = useIntl();
  const datepickerRef = useRef<HTMLDivElement>(null);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const budgetInputRef = useRef<HTMLInputElement>(null);
  const [datePickerDialogOpen, setDatePickerDialogOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>('startDate');
  const { fetchPlaceDetails } = useGooglePlaces();

  useClickAway(datepickerRef, () => setDatePickerDialogOpen(false));

  const location = data.location?.query?.split('|');

  const [search, setSearch] = useState<{
    placeId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    maxBudget: number | undefined;
    address: string | undefined;
  }>({
    address: location?.[0],
    placeId: location?.[1],
    startDate: data.startDate,
    endDate: data.endDate,
    maxBudget: data.maxBudget,
  });

  const handleDatesChange = (dates: { startDate: Moment | null; endDate: Moment | null }) => {
    setSearch((s) => ({
      ...s,
      startDate: dates.startDate?.format('YYYY-MM-DD'),
      endDate: dates.endDate?.format('YYYY-MM-DD'),
    }));

    if (focusedInput === 'endDate' && !isEmpty(dates.startDate) && !isEmpty(dates.endDate)) {
      setDatePickerDialogOpen(false);
      budgetInputRef.current?.focus();
    }
  };

  const handleFocusChange = () => {
    if (focusedInput === 'startDate') setFocusedInput('endDate');
    else setFocusedInput('startDate');
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!search.placeId) {
      locationInputRef.current?.focus();
      return;
    }

    if (isEmpty(search.startDate)) {
      setDatePickerDialogOpen(true);
      setFocusedInput('startDate');
      return;
    }

    if (isEmpty(search.endDate)) {
      setDatePickerDialogOpen(true);
      setFocusedInput('endDate');
      return;
    }

    const location: Location = { lat: undefined, lng: undefined };
    if (search.placeId?.startsWith('colette-')) {
      if (search.placeId === 'colette-paris') {
        location.lat = PARIS_COORDINATES[0];
        location.lng = PARIS_COORDINATES[1];
        location.query = search.address + '|colette-paris';
      } else if (search.placeId === 'colette-lyon') {
        location.lat = LYON_COORDINATES[0];
        location.lng = LYON_COORDINATES[1];
        location.query = search.address + '|colette-lyon';
      } else {
        location.lat = BORDEAUX_COORDINATES[0];
        location.lng = BORDEAUX_COORDINATES[1];
        location.query = search.address + '|colette-bordeaux';
      }
    } else {
      const place = await fetchPlaceDetails(search.placeId);
      if (place) {
        location.lat = place.geometry.location.lat();
        location.lng = place.geometry.location.lng();
        location.query = search.address + '|' + search.placeId;
      }
    }

    onSubmit({
      location,
      startDate: search.startDate,
      endDate: search.endDate,
      maxBudget: search.maxBudget,
    });
  };

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit}
        className={clsx(className, 'bg-white items-center relative')}
      >
        <div className="grid grid-cols-4 divide-x divide-gray-300 flex-1 border-r border-gray-300">
          <SearchAutocomplete
            onSelect={({ placeId, address }) => setSearch((s) => ({ ...s, placeId, address }))}
            selected={{ placeId: search.placeId, address: search.address }}
            value={
              data.boundingBox
                ? intl.formatMessage({
                    id: 'search_bar.location.map_area',
                    defaultMessage: 'Map area',
                  })
                : undefined
            }
            className="py-5 px-4"
            ref={locationInputRef}
          />

          <DateButton
            onClick={() => setDatePickerDialogOpen((p) => !p)}
            label={intl.formatMessage({
              id: 'search_bar.checkin.label',
              defaultMessage: 'Checkin',
            })}
            noValueContent={
              <div className="text-gray-600 truncate">
                <FormattedMessage id="search_bar.checkin.no_content" defaultMessage="Add a date" />
              </div>
            }
            className="py-5 px-4"
          >
            {intl.formatDate(search.startDate)}
          </DateButton>

          <DateButton
            onClick={() => setDatePickerDialogOpen((p) => !p)}
            label={intl.formatMessage({
              id: 'search_bar.checkout.label',
              defaultMessage: 'Checkout',
            })}
            noValueContent={
              <div className="text-gray-600 truncate">
                <FormattedMessage id="search_bar.checkout.no_content" defaultMessage="Add a date" />
              </div>
            }
            className="py-5 px-4"
          >
            {intl.formatDate(search.endDate)}
          </DateButton>

          <Input
            ref={budgetInputRef}
            type="number"
            label={intl.formatMessage({
              id: 'search_bar.budget.label',
              defaultMessage: 'Budget',
            })}
            value={search.maxBudget || ''}
            onChange={(e) => setSearch((s) => ({ ...s, maxBudget: e.target.valueAsNumber }))}
            placeholder={intl.formatMessage({
              id: 'search_bar.budget.placeholder',
              defaultMessage: 'Enter your budget',
            })}
            className="py-5 px-4"
          />
        </div>

        <SearchButton className="my-3 mx-4" />

        {datePickerDialogOpen ? (
          <div className="w-full mt-1 flex place-content-center absolute origin-bottom top-[92px] z-50">
            <div className="bg-white p-6 rounded-2xl shadow-md" ref={datepickerRef}>
              <Picker
                numberOfMonths={2}
                startDate={search.startDate ? moment(search.startDate) : null}
                endDate={search.endDate ? moment(search.endDate) : null}
                onDatesChange={handleDatesChange}
                onFocusChange={handleFocusChange}
                focusedInput={focusedInput}
              />
            </div>
          </div>
        ) : null}
      </form>
    </>
  );
};

export default SearchBar;
