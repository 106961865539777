import clsx from 'clsx';

type Props = React.ComponentProps<'button'>;

const SearchButton = (props: Props) => {
  return (
    <button
      {...props}
      type="submit"
      className={clsx(
        props.className,
        'flex items-center justify-center w-16 h-16 bg-yellow-500 rounded-2xl focus:outline-none'
      )}
    >
      <svg
        className="inline-block"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#111111"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M13.3578 18.153C17.9644 16.1953 20.1118 10.8738 18.154 6.26719C16.1962 1.66059 10.8748 -0.486726 6.26817 1.47103C1.66157 3.42879 -0.485738 8.75026 1.47202 13.3569C3.42978 17.9635 8.75124 20.1108 13.3578 18.153Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.221 16.22L23.25 23.25"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
};

export default SearchButton;
