import { useCallback, useEffect, useRef, useState } from 'react';

interface MultiRangeSliderProps {
  min: number;
  max: number;
  onChange: (e: any) => void;
  step: number;
  defaultValues?: { maxBudget?: number; minBudget?: number };
}

const MultiRangeSlider = ({ min, max, onChange, step, defaultValues }: MultiRangeSliderProps) => {
  const [minVal, setMinVal] = useState(defaultValues?.minBudget || min);
  const [maxVal, setMaxVal] = useState(defaultValues?.maxBudget || max);
  const minValRef = useRef(defaultValues?.minBudget || min);
  const maxValRef = useRef(defaultValues?.maxBudget || max);
  const range = useRef<HTMLDivElement>(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // init budget values
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  return (
    <div className="multi-range-slider_container">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={minVal}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
          onChange({ min: value, max: maxVal });
        }}
        className="multi-range-slider_thumb multi-range-slider_thumb--left"
        style={{ zIndex: minVal > max - 100 ? 5 : 3 }}
      />
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={maxVal}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
          onChange({ min: minVal, max: value });
        }}
        className="multi-range-slider_thumb multi-range-slider_thumb--right"
      />

      <div className="multi-range-slider_slider">
        <div className="multi-range-slider_slider__track" />
        <div ref={range} className="multi-range-slider_slider__range" />
      </div>
    </div>
  );
};

export default MultiRangeSlider;
