import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Skeleton from 'components/Skeleton';

interface LabelProps extends React.PropsWithoutRef<JSX.IntrinsicElements['label']> {
  required?: boolean;
}

const Label = ({ required, ...props }: LabelProps) => (
  <div className="flex items-center justify-between">
    <label htmlFor={props.id} className="font-sans text-base font-light text-black" {...props} />
    {required ? null : (
      <span className="font-sans text-xs font-light text-black uppercase">
        <FormattedMessage id="text_field.optional" defaultMessage="Optional" />
      </span>
    )}
  </div>
);

interface InputProps extends React.PropsWithRef<JSX.IntrinsicElements['input']> {
  error?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ error, className, ...props }, ref) => (
  <input
    className={clsx(
      className,
      error
        ? 'border-red-600 ring-red-600 focus:ring-red-600 focus:ring-1 pr-8'
        : 'focus:border-gray-600',
      'field form-field font-sans font-medium text-base text-black appearance-none !p-0 flex content-center !px-4 h-15'
    )}
    ref={ref}
    {...props}
  />
));

interface TextFieldProps extends InputProps {
  loading?: boolean;
  trailingAddon?: React.ReactNode;
  label?: React.ReactNode;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, loading, trailingAddon, ...props }, ref) => (
    <div className="w-full">
      {loading && label ? <Skeleton className="h-6" /> : null}
      {!loading && label ? (
        <Label id={props.id} required={props.required}>
          {label}
        </Label>
      ) : null}

      <div className={clsx('relative flex items-center', { 'mt-2.5': !!label })}>
        {loading ? (
          <Skeleton className={'field form-field py-4 px-5 h-14'} />
        ) : (
          <>
            <Input
              error={error}
              ref={ref}
              {...props}
              className={clsx(props.className, { 'pr-16': !!trailingAddon })}
            />

            {trailingAddon}
          </>
        )}

        {error ? (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : null}
      </div>

      {error ? (
        <p
          className="mt-2 ml-1 font-sans text-sm font-normal text-left text-red-600"
          id={`${props.id}-error`}
        >
          {error}
        </p>
      ) : null}
    </div>
  )
);

export default TextField;
