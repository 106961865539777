import clsx from 'clsx';

interface Props {
  active: boolean;
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
}

const RequestsNavigationTab = ({ active, className, onClick, children }: Props) => {
  return (
    <button
      type="button"
      className={clsx(
        className,
        'w-1/2 py-3 text-base whitespace-nowrap transition duration-150 border-b focus:outline-none truncate',
        active
          ? 'border-black font-medium'
          : 'font-light border-gray-200 text-gray-500 hover:text-black hover:border-black'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default RequestsNavigationTab;
